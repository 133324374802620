import {LayoutFieldFragment} from "./LayoutFieldFragment";

export interface CommonLayoutProps {
  values?: Map<string, number>;
  textValues?: Map<string, string>;
  onChange: Function;
  canEdit: boolean;
  userRole: UserRole;
}

interface LayoutComponentProps extends CommonLayoutProps {
  data: ObjectWithId;
  fieldFragmentsMap: Map<string, FieldInfo>;
}

export const LayoutComponent = ({ data, fieldFragmentsMap, ...rest }: LayoutComponentProps) => {
  const fieldFragmentId = data.id;

  return (
    <div className="grow">
      <LayoutFieldFragment
        key={fieldFragmentId}
        fieldFragmentId={fieldFragmentId}
        fieldFragment={fieldFragmentsMap.get(fieldFragmentId)?.fieldFragment}
        fieldDefinition={fieldFragmentsMap.get(fieldFragmentId)?.fieldDefinition}
        {...rest}
      />
    </div>
  );
};
