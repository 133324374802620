import * as React from 'react';
import { Trans } from 'react-i18next';
import { Select } from 'antd';
import { Button, LabeledCheckbox, LabeledRadioButton } from '../../../app/components/Forms';
import { fieldFragmentOperations } from '../constants';
import {UserRoles} from './UserRoles';
import { TextField } from './TextField';

interface Props {
  onChange: Function;
  onDelete: Function;
  customField: FieldFragment;
};

const showFieldVisibility = false;

export const CustomFieldFragment: React.FC<Props> = (props: Props) => {
  const useSelect = false;
  const {customField, onChange} = props;
  const localeOnChange = React.useCallback((operationType:FieldFragmentOperation) => {
    onChange('operation', operationType, customField.id);
  }, [customField, onChange]);

  const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    // Use 'data-name' html element attribute because radio button list name would not contain the FieldFragment prop
    // name.
    onChange(
      event.currentTarget.getAttribute('data-name') || event.currentTarget.name,
      event.currentTarget.type === 'checkbox' ? event.currentTarget.checked : event.currentTarget.value,
      props.customField.id,
    );
  }

  const onValueChange = (name:string, value:string, language:string) =>
    props.onChange(name, value, props.customField.id, language);

  const onVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(
      'visibility',
      event.target.checked ? 'hidden-by-default': 'visible-by-default',
      props.customField.id,
    );
  };

  return (
    <div className='w-full border-dashed border-2 p-4 mt-4'>
      <h3 className='font-bold capitalize float-right'>{props.customField.inputType}</h3>
      {/** NAME */}
      <TextField
        name="name"
        label="custom-fields.custom-field.name"
        values={props.customField.name}
        onChange={onValueChange}
        dataTestId="custom-field-name"/>

      {/** DESCRIPTION */}
      <TextField
        name="description"
        label="custom-fields.custom-field.description"
        values={props.customField.description}
        onChange={onValueChange}
        dataTestId="custom-field-description"/>

        {props.customField.inputType === 'number' && (
        <>
           <hr  className='mt-4 mb-4'/>
           <div>
           {/** OPERATIONS */}
           {!useSelect && fieldFragmentOperations.map((name) =>
             <LabeledRadioButton
               key={name}
               name={`${props.customField.id}-operation`}
               label={name}
               value={name}
               data-name="operation"
               checked={props.customField.operation === name}
               data-test-id="custom-field-operation"
               data-test-value={name}
               onChange={onInputChange} /> )}

               {useSelect && (
                <div className='mb-4'>
                  <label className='pb-2'>
                    <Trans i18nKey="custom-fields.operation" />
                  </label>
                  <div>
                   <Select
                    defaultValue={props.customField.operation}
                    className="w-full"
                    onChange={localeOnChange}
                    value={props.customField.operation}
                    data-test-id="custom-field-operation"
                    options={fieldFragmentOperations.map(curr => ({value: curr,  label: <Trans i18nKey={curr} /> }) )}
                  />
                  </div>
                </div>
               )}
           </div>
         </>
        )}

      {/** EDIT FIELD ENTRY BY USER-ROLE */}
      <hr  className='mt-4 mb-4'/>
      <div>
        <UserRoles customField={props.customField} onChange={props.onChange}/>
      </div>

      {/** DEFAULT FIELD VISIBILITY IN ASSIGNED SHOPS  */}
      {showFieldVisibility && (
      <>
        <hr  className='mt-4 mb-4'/>
        <div>
          <LabeledCheckbox
            name={`${props.customField.id}-visibility`}
            data-name="visibility"
            label={'custom-fields.visibility'}
            checked={props.customField.visibility === 'hidden-by-default'}
            dataTestId="custom-field-visibility"
            onChange={onVisibilityChange} />
        </div>
      </>
      )}

      {/** ACTIONS */}
      <div className='mt-6 flex justify-end'>
        <Button label="custom-fields.remove" onClick={() => { props.onDelete(props.customField.id) }} />
      </div>
    </div>
  );
}
