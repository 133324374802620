import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './i18n';
import { useTranslation } from 'react-i18next';
import { NavBar } from './app/components/NavBar';
import { store } from './app/store';
import { me, ping } from './features/authentication/actions';
import { RequireAuth } from './features/authentication/RequireAuth';
import { RegisterRoutes } from './features/register/routes';
import { EntriesRoutes } from './features/entries/routes';
import { UserRoutes } from './features/users/routes';
import { ShopRoutes } from './features/shops/routes';
import { CustomFieldRoutes } from './features/custom-fields/routes';
import { BalancesRoutes } from './features/balances/routes';
import { ChecksRoutes } from './features/checks/routes';
import { ToastContainer } from './features/toast/ToastContainer';
import { Login } from './features/authentication/Login';
import { ProfileRoutes } from './features/profile/ProfileRoutes';
import {LayoutTemplateRoutes} from './features/layout-templates/routes';

function App() {
  useTranslation(); // this will update the translations in the routes
  return (
    <Provider store={store}>
      <BrowserRouter>
        <NavBar />
        <div className="container mx-auto px-4 h-full">
          <Routes>
            {/* REGISTER */}
            <Route path='/register/*' element={<RequireAuth><RegisterRoutes /></RequireAuth>} />
            {/* ENTRY */}
            <Route path='/entries/*' element={<RequireAuth><EntriesRoutes /></RequireAuth>} />
            {/* SHOP */}
            <Route path='/shops/*' element={<RequireAuth><ShopRoutes /></RequireAuth>} />
            {/* CHECK */}
            <Route path='/checks/*' element={<RequireAuth><ChecksRoutes /></RequireAuth>} />
            {/* USERS */}
            <Route path='/users/*' element={<RequireAuth><UserRoutes /></RequireAuth>} />
            <Route path="/profile/*" element={<RequireAuth><ProfileRoutes /></RequireAuth>} />
            {/* CUSTOM FIELDS */}
            <Route path='/custom-fields/*' element={<RequireAuth><CustomFieldRoutes /></RequireAuth>} />
            {/* BALANCES */}
            <Route path='/balances/*' element={<RequireAuth><BalancesRoutes /></RequireAuth>} />
            {/* LAYOUT-TEMPLATES */}
            <Route path='/layout-templates/*' element={<RequireAuth><LayoutTemplateRoutes /></RequireAuth>} />
            {/* Login */}
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            {/* 404 */}
            <Route
              path="*"
              element={<NoMatch />}
            />
          </Routes>
        </div>
        <ToastContainer />
      </BrowserRouter>
    </Provider>
  );
}

function NoMatch() {
  return (
    <div>
      <h3>
        No match for <code>{useLocation().pathname}</code>
      </h3>
    </div>
  );
}

(window as any).__STORE__ = store;

// Trigger authentication check
store.dispatch(me());

// Set authentication ping interval
if ((window as any).__ping_interval) {
  window.clearInterval((window as any).__ping_interval);
}
(window as any).__ping_interval = window.setInterval(() => {
  store.dispatch(ping());
}, 1000 * 60 * 15);

export default App;
