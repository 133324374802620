import { sortBy } from 'lodash';
import { Trans } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks';
import { selectShops } from '../../features/shops/shopsSlice';
import { CSSProperties, useEffect, useState } from 'react';
import { findAll } from '../../features/shops/actions';
import { getFirstShopLayoutTemplate } from '../../features/shops/util';
import { Link } from 'react-router-dom';

interface ItemProps {
  id: string;
  name: string; // Shop name (en)
  shopLayoutTemplates: Array<Partial<ShopLayoutTemplate>> | null; // Shop layoutTemplate
  showLayoutTemplateName: boolean;
}

const Item: React.FC<ItemProps> = (props: ItemProps) => (
  <div className="grid grid-cols-1">
    <Link to={`/shops/edit/${props.id}`}>
      <div>{props.name}</div>
    </Link>
    {props.showLayoutTemplateName && (
      <span className="text-gray-400">
        <Trans i18nKey="assigned template" />
        :&nbsp;
        <Trans i18nKey={getFirstShopLayoutTemplate(props.shopLayoutTemplates)?.layoutTemplate?.name || '-'} />
      </span>
    )}
  </div>
);

interface ListProps {
  title: React.ReactNode;
  shops: Shop[] | null;
  empty?: React.ReactNode;
  showLayoutTemplateName: boolean;
}

const List = (props: ListProps) => (
  <div className="mb-4">
    <h2 className="text-2xl font-bold mb-4">{props.title}</h2>
    <div className="flex flex-col gap-y-4">
      {props.shops?.map((shop: Shop) => (
        <Item
          key={shop.id}
          name={shop.name}
          id={shop.id}
          shopLayoutTemplates={shop.shopLayoutTemplates}
          showLayoutTemplateName={props.showLayoutTemplateName}
        />
      ))}
    </div>
    {props.shops && props.shops.length === 0 && props.empty}
  </div>
);

interface Props {
  assignedShops: Shop[] | null;
  style?: CSSProperties;
  showLayoutTemplateName?: boolean;
}

export const ListLinkedShops: React.FC<Props> = ({
  assignedShops,
  style = {},
  showLayoutTemplateName = true,
}: Props) => {
  const dispatch = useAppDispatch();
  const allShops = useAppSelector(selectShops);
  // Assigned shops with added layoutTemplate details
  const [localShops, setLocaleShops] = useState<Array<Shop>>([]);

  useEffect(() => {
    dispatch(findAll());
  }, [dispatch]);

  // Set localShops
  useEffect(() => {
    if (assignedShops && allShops) {
      const shops = assignedShops.map((shop) => allShops?.find((item) => item.id === shop.id)) as Shop[];
      // .filter((shop) => {
      //   return typeof shop !== undefined;
      // }) as Shop[];
      setLocaleShops(sortBy(shops, 'name'));
    }
  }, [allShops, assignedShops]);

  return (
    <div className="o-box bg-white p-8 w-full" style={style}>
      {/** ASSIGNED SHOPS */}
      <div className="mb-4">
        <List
          shops={localShops}
          title={
            <>
              <Trans i18nKey="Assigned shops" />
              <span className="text-base font-normal pl-2">({assignedShops?.length || 0})</span>
            </>
          }
          empty={
            <div>
              <Trans i18nKey="no assigned shops" />
            </div>
          }
          showLayoutTemplateName={showLayoutTemplateName}
        />
      </div>
    </div>
  );
};

interface ListShopsSimpleProps {
  shops: Shop[] | null;
  style?: CSSProperties;
  showLayoutTemplateName?: boolean;
}
export const ListShopsSimple: React.FC<ListShopsSimpleProps> = ({
  shops,
  style = {},
  showLayoutTemplateName = false,
}: ListShopsSimpleProps) => {
  return (
    <div className="o-box bg-white p-8 w-full" style={style}>
      {/** ASSIGNED SHOPS */}
      <div className="mb-4">
        <List
          shops={shops}
          showLayoutTemplateName={showLayoutTemplateName}
          title={
            <>
              <Trans i18nKey="Assigned shops" />
              <span className="text-base font-normal pl-2">({shops?.length || 0})</span>
            </>
          }
          empty={
            <div>
              <Trans i18nKey="no assigned shops" />
            </div>
          }
        />
      </div>
    </div>
  );
};
