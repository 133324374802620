import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToolBar } from '../../app/components/ToolBar';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Nav } from './components/ToolBarNav';
import { Actions } from './components/ToolBarActions';
import { Trans } from 'react-i18next';
import { selectFieldFragmentsData } from '../custom-fields/customFieldsSlice';
import { findAll as findAllCustomFields, findFieldFragments } from '../custom-fields/actions';
import { uniqueid } from './editor/utils';
import { sortBy } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Editor } from './editor/Editor';
import type { EditorRef } from './editor/Editor';
import { fieldFragmentlabel2, fieldFragmentlabels2 } from './utils';
import { emptyTemplate } from './editor/data';
import { selectIsWaiting, selectLayoutTemplate, selectLayoutTemplates } from './layoutTemplatesSlice';
import { findAllLayoutTemplates, findOneLayoutTemplate, updateLayoutTemplate } from './layoutTemplateActions';
import { ActionsDrawer } from './components/ActionsDrawer';
import { useBoolean } from 'ahooks';

export function Edit() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const isWaiting = useAppSelector(selectIsWaiting);
  const layoutTemplate = useAppSelector(selectLayoutTemplate);
  const fieldFragmentsData = useAppSelector(selectFieldFragmentsData);
  const layoutTemplates = useAppSelector(selectLayoutTemplates);

  // Using useRef hook to get Editor items only on "save" and not every update to the Editor
  const editorRef = useRef<EditorRef>(null);

  const [sidebarItems, setSidebarItems] = useState<Array<SideBarData>>([]);
  const [labels, setLables] = useState<Array<LabelItem>>([]);
  // Do not edit the default layout template on a shop layout template.
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [localLayoutTemplate, setLocalLayoutTemplate] = useState<LayoutTemplate | null>(null);
  const [localLayoutTemplateNodes, setLocalLayoutTemplateNodes] = useState<Array<LayoutNode> | null>(null);
  const [layoutTemplateName, setLayoutTemplateName] = useState<string | undefined>();

  const [showActionsDrawer, { toggle: toggleActionsDrawer, setFalse: hideActionsDrawer }] = useBoolean(false);

  useEffect(() => {
    if (typeof id !== 'string') {
      return;
    }
    dispatch(findAllLayoutTemplates());
    dispatch(findOneLayoutTemplate(id));
    dispatch(findAllCustomFields());
    dispatch(findFieldFragments());
  }, [dispatch, id]);

  // Is LayoutTemplate editable ?
  useEffect(() => {
    if (!layoutTemplate) {
      return;
    }
    setCanEdit(true);
    if (Array.isArray(layoutTemplate.nodes) && layoutTemplate.nodes.length === 0) {
      setLocalLayoutTemplateNodes(emptyTemplate);
    } else if (!Array.isArray(layoutTemplate.nodes)) {
      setLocalLayoutTemplateNodes(emptyTemplate);
    } else {
      setLocalLayoutTemplateNodes(layoutTemplate.nodes);
    }
    setLocalLayoutTemplate(layoutTemplate);
  }, [layoutTemplate]);

  useEffect(() => {
    if (!fieldFragmentsData) {
      return;
    }
    const items: Array<SideBarData> = fieldFragmentsData.map((fieldFragmentsDataItem) => {
      return {
        id: uniqueid('BAR'),
        type: 'SIDEBAR_ITEM',
        key: fieldFragmentlabel2(fieldFragmentsDataItem[1]),
        enabled: true,
        component: {
          type: 'FIELD_FRAGMENT',
          id: fieldFragmentsDataItem[1].fieldFragment.id,
        },
      };
    });
    setSidebarItems(sortBy(items, 'key'));
    setLables(fieldFragmentlabels2(fieldFragmentsData));
  }, [fieldFragmentsData]);

  const onCopyLayoutTemplate = (copyFromLayoutTemplateWithId: string) => {
    const copyFromLayoutTemplate = layoutTemplates?.find((item) => item.id === copyFromLayoutTemplateWithId);
    if (copyFromLayoutTemplate && copyFromLayoutTemplate.nodes) {
      setLocalLayoutTemplateNodes(copyFromLayoutTemplate.nodes);
    }
  };

  const updateTemplateName = useCallback(
    (templateName: string | undefined) => {
      if (typeof templateName === 'string') {
        if (localLayoutTemplate) {
          setLocalLayoutTemplate({ ...localLayoutTemplate, name: templateName });
        }
        setLayoutTemplateName(templateName);
        // only update LayoutTemplate name (not the layout nodes)
        dispatch(
          updateLayoutTemplate({
            layoutTemplate: { id, name: templateName },
          }),
        );
      }
    },
    [id, dispatch, localLayoutTemplate],
  );

  const onSave = useCallback(() => {
    if (editorRef.current && layoutTemplate) {
      const data = editorRef.current.getLayoutTemplate();
      if (data !== null) {
        dispatch(
          updateLayoutTemplate({
            layoutTemplate: { ...localLayoutTemplate, nodes: data },
          }),
        );
      }
    }
  }, [dispatch, layoutTemplate, localLayoutTemplate]);

  return (
    <>
      <ToolBar
        title={<Trans i18nKey="layout-templates.edit" />}
        nav={<Nav />}
        showActions={canEdit}
        actions={
          <Actions
            onSave={onSave}
            disabled={isWaiting}
            name={layoutTemplateName || layoutTemplate?.name}
            toggleActionsDrawer={toggleActionsDrawer}
          />
        }
      />
      <div>
        {canEdit && localLayoutTemplateNodes && (
          <DndProvider backend={HTML5Backend}>
            <Editor sidebarItems={sidebarItems} labels={labels} ref={editorRef} nodes={localLayoutTemplateNodes} />
          </DndProvider>
        )}
      </div>
      {localLayoutTemplate && showActionsDrawer && (
        <ActionsDrawer
          layoutTemplate={localLayoutTemplate}
          onClose={hideActionsDrawer}
          onNameChange={updateTemplateName}
          layoutTemplates={layoutTemplates}
          onCopyLayoutTemplate={onCopyLayoutTemplate}
        />
      )}
    </>
  );
}
