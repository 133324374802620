import { Drawer } from 'antd';
import { Trans } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ListLinkedShops } from '../../../app/components/ListLinkedShops';
import { useEffect, useState } from 'react';
import { selectCustomField } from '../customFieldsSlice';
import { findOne } from '../actions';

interface InfoDrawerProps {
  onClose: Function;
  fieldDefinition: FieldDefinition | undefined;
}

export const InfoDrawer = ({ onClose, fieldDefinition }: InfoDrawerProps) => {
  const dispatch = useAppDispatch();
  const customFieldDefinition = useAppSelector(selectCustomField);
  const [localeLinkedShops, setLocaleLinkedShops] = useState<Shop[]>([]);

  // Fetch customField
  useEffect(() => {
    if (fieldDefinition?.id) {
      dispatch(findOne(fieldDefinition.id));
    }
  }, [dispatch, fieldDefinition]);

  // Copy FieldDefinition into local FieldDefinition for edit
  useEffect(() => {
    if (customFieldDefinition) {
      setLocaleLinkedShops(customFieldDefinition.shops || []);
    }
  }, [fieldDefinition, customFieldDefinition]);

  return (
    <Drawer
      title={
        <>
          <Trans i18nKey="custom-fields.title" /> / {fieldDefinition?.name?.en || '-'}
        </>
      }
      size="large"
      placement="right"
      open={true}
      onClose={() => {
        onClose();
      }}
    >
      {fieldDefinition && <ListLinkedShops assignedShops={localeLinkedShops} style={{padding:'1rem'}} />}
    </Drawer>
  );
};
