import { Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useAppDispatch } from '../../../app/hooks';
import { findOneWithShops } from '../layoutTemplateActions';
import { ListShopsSimple } from '../../../app/components/ListLinkedShops';

interface InfoDrawerProps {
  onClose: Function;
  layoutTemplate: LayoutTemplate | undefined;
}

export const InfoDrawer = ({ onClose, layoutTemplate }: InfoDrawerProps) => {
  const dispatch = useAppDispatch();
  // const customFieldDefinition = useAppSelector(selectCustomField);
  const [localeLinkedShops, setLocaleLinkedShops] = useState<Shop[]>([]);

  // Fetch customField
  useEffect(() => {
    const fetchData = async () => {
      if (layoutTemplate?.id) {
        const result = await dispatch(findOneWithShops(layoutTemplate.id));
        if (Array.isArray(result.payload.shopLayoutTemplates)) {
          setLocaleLinkedShops(
            result.payload.shopLayoutTemplates.map((shopLayoutTemplate: ShopLayoutTemplate) => shopLayoutTemplate.shop),
          );
        } else {
          setLocaleLinkedShops([]);
        }
      }
    };
    fetchData();
  }, [dispatch, layoutTemplate]);

  return (
    <Drawer
      title={
        <>
          <Trans i18nKey="layout-templates.title" /> / {layoutTemplate?.name || '-'}
        </>
      }
      size="large"
      placement="right"
      open={true}
      onClose={() => {
        onClose();
      }}
    >
      {localeLinkedShops && <ListShopsSimple shops={localeLinkedShops} style={{ padding: '1rem' }} />}
    </Drawer>
  );
};
