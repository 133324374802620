import { useRef } from 'react';
import { useDrag } from 'react-dnd';
import { verbose } from '../constants';

const style = {
  border: '1px dashed black',
  padding: '0.5rem 1rem',
  backgroundColor: '#eee',
  cursor: 'move',
};

interface ComponentProps {
  data: ObjectWithId;
  path: string;
  label: LabelItem | undefined;
}

const Component = ({ data, path, label }: ComponentProps) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'COMPONENT',
      item: { type: 'COMPONENT', id: data.id, path },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [path, data],
  );

  const opacity = isDragging ? 0.25 : 1;
  drag(ref);

  return (
    <div ref={ref} style={{ ...style, opacity }} className="component draggable">
      <strong>{label && label.label}</strong>
      {verbose > 0 && (
        <code>
          [cmp] id:{data.id.slice(0, 4)}... path:{path}
        </code>
      )}
    </div>
  );
};
export default Component;
