import { createSlice } from '@reduxjs/toolkit';
import { getResponseError } from '../../api';
import { RootState } from '../../app/store';
import { findAll, findOne, create, update, findFieldFragments, remove } from './actions';

interface customFieldsState {
  error: boolean | null;
  isWaiting: boolean;
  items: FieldDefinition[] | null;
  current: FieldDefinition | null;
  fieldFragmentList: FieldFragment[] | null; // for UI selection only, use "items" otherwise
  fieldFragmentData: Array<[string, FieldFragmentData]> | null;
  message: string;
}

const initialState: customFieldsState = {
  error: false,
  isWaiting: false,
  items: null,
  current: null,
  fieldFragmentList: null,
  fieldFragmentData: null,
  message: '',
}

function fieldFragmentDataFromFieldDefinitions(fieldDefinitions:Array<FieldDefinition>):Array<[string, FieldFragmentData]> {
  const result = new Map<string, FieldFragmentData>();
  if (Array.isArray(fieldDefinitions)) {
    for (const fieldDefinition of fieldDefinitions) {
      for (const fieldFragment of fieldDefinition.fieldFragments) {
        result.set(fieldFragment.id, {fieldFragment, fieldDefinition});
      }
    }
  }
  return Array.from(result.entries());
}

export const customFieldsSlice = createSlice({
  name: 'customFields',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    //
    .addCase(findAll.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.items = null;
      state.message = '';
      state.current = null;
      state.fieldFragmentData = null;
    })
    .addCase(findAll.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.items = action.payload.items;
      state.message = '';
      if (action.payload.items) {
        state.fieldFragmentData = fieldFragmentDataFromFieldDefinitions(action.payload.items);
      } else {
        state.fieldFragmentData = null;
      }
    })
    .addCase(findAll.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.items = null;
      state.message = getResponseError(action);
      state.fieldFragmentData = null;
    })
    //
    .addCase(findOne.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = '';
    })
    .addCase(findOne.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = '';
    })
    .addCase(findOne.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    //
    .addCase(create.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'Adding new Activity';
    })
    .addCase(create.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'New Activity Saved';
      // reload list on custom field changes
      state.fieldFragmentList = null;
    })
    .addCase(create.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    //
    .addCase(update.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'Saving Activity';
    })
    .addCase(update.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'Activity Saved';
      // reload list on custom field changes
      state.fieldFragmentList = null;
    })
    .addCase(update.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    //
    .addCase(findFieldFragments.fulfilled, (state, action) => {
      // reload list on custom field changes
      state.fieldFragmentList = action.payload.items;
    })
    .addCase(findFieldFragments.rejected, (state, action) => {
      // do not set full error state because this in only for a UI list
      state.message = getResponseError(action);
    })
    //
    .addCase(remove.pending, (state, _action) => {
      // reload list on custom field changes
      state.fieldFragmentList = null;
    })
    .addDefaultCase(() => { }),
});

// SELECTORS
export const selectCustomFields = (state: RootState) => state.customFields.items;
export const selectCustomField = (state: RootState) => state.customFields.current;
export const selectAvailableCustomFields = (state: RootState) => state.customFields.items || [];
export const selectFieldFragmentsList = (state: RootState) => state.customFields.fieldFragmentList; // for UI selection only
export const selectFieldFragmentsData = (state: RootState) => state.customFields.fieldFragmentData; // for UI selection only

export const selectMessage = (state: RootState): string => state.customFields.message;
export const selectErrorMessage = (state: RootState): string  => state.customFields.error === true ? state.customFields.message : '';
export const selectIsWaiting = (state: RootState): boolean => state.customFields.isWaiting;

export default customFieldsSlice.reducer;
