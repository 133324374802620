import { createSlice } from '@reduxjs/toolkit';
import {
  findAllShopLayoutTemplates,
  findDefaultTemplate,
  findOne,
  resetTemplate,
  updateShopLayoutTemplates,
} from './actions';
import { getResponseError } from '../../api';
import { RootState } from '../../app/store';
import { fulfilled, pending, rejected } from '../../app/storeUtils';

interface shopLayoutTemplateState extends StoreState {
  shopLayoutTemplates: ShopLayoutTemplate[] | null; // all shop layout templates
  shopLayoutTemplate: ShopLayoutTemplate | null; // The shopLayoutTemplate to edit
  defaultLayoutTemplate: ShopLayoutTemplate | null; // List of LayoutTemplates (only "DEFAULT_TEMPLATE" for now.)
}

const initialState: shopLayoutTemplateState = {
  error: false,
  isWaiting: false,
  shopLayoutTemplates: null,
  shopLayoutTemplate: null,
  defaultLayoutTemplate: null,
  message: '',
};

export const shopLayoutTemplates = createSlice({
  name: 'shopLayoutTemplates',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      // GET ALL SHOP-LAYOUT-TEMPLATES
      .addCase(findAllShopLayoutTemplates.pending, (state) => {
        pending(state);
        state.shopLayoutTemplates = null;
      })
      .addCase(findAllShopLayoutTemplates.fulfilled, (state, action) => {
        fulfilled(state);
        state.shopLayoutTemplates = action.payload;
      })
      .addCase(findAllShopLayoutTemplates.rejected, (state, action) => {
        rejected(state, getResponseError(action));
        state.shopLayoutTemplates = null;
      })
      // GET SINGLE LAYOUT-TEMPLATE
      .addCase(findOne.pending, (state) => {
        pending(state);
        state.shopLayoutTemplate = null;
      })
      .addCase(findOne.fulfilled, (state, action) => {
        fulfilled(state);
        state.shopLayoutTemplate = action.payload;
      })
      .addCase(findOne.rejected, (state, action) => {
        rejected(state, getResponseError(action));
        state.shopLayoutTemplate = null;
      })
      // UPDATE LAYOUT-TEMPLATE
      .addCase(updateShopLayoutTemplates.pending, (state) => {
        pending(state, 'Saving Layout Template');
      })
      .addCase(updateShopLayoutTemplates.fulfilled, (state, action) => {
        fulfilled(state, 'Layout Template saved');
        state.shopLayoutTemplate = action.payload;
      })
      .addCase(updateShopLayoutTemplates.rejected, (state, action) => {
        rejected(state, getResponseError(action));
        // state.shopLayoutTemplate = null;
      })
      // GET DEFAULT LAYOUT-TEMPLATE
      .addCase(findDefaultTemplate.pending, (state) => {
        pending(state);
        state.defaultLayoutTemplate = null;
      })
      .addCase(findDefaultTemplate.fulfilled, (state, action) => {
        fulfilled(state);
        state.defaultLayoutTemplate = action.payload;
      })
      .addCase(findDefaultTemplate.rejected, (state, action) => {
        rejected(state, getResponseError(action));
        state.defaultLayoutTemplate = null;
      })
      .addCase(resetTemplate, (state) => {
        state.shopLayoutTemplate = null;
      })
      // DEFAULT
      .addDefaultCase(() => {}),
});

// SELECTORS
export const selectShopLayoutTemplates = (state: RootState) => state.shopLayoutTemplates.shopLayoutTemplates;
export const selectShopLayoutTemplate = (state: RootState) => state.shopLayoutTemplates.shopLayoutTemplate;
export const selectMessage = (state: RootState): string => state.shopLayoutTemplates.message;
export const selectErrorMessage = (state: RootState): string =>
  state.shopLayoutTemplates.error === true ? state.shopLayoutTemplates.message : '';
export const selectIsWaiting = (state: RootState): boolean => state.shopLayoutTemplates.isWaiting;

export const selectDefaultLayoutTemplate = (state: RootState) => state.shopLayoutTemplates.defaultLayoutTemplate;

export default shopLayoutTemplates.reducer;
