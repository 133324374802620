export function pending(state: any, message: string = '') {
  state.error = null;
  state.isWaiting = true;
  state.message = message;
}

export function fulfilled(state: any, message: string = '') {
  state.error = false;
  state.isWaiting = false;
  state.message = message;
}

export function rejected(state: any, message: string = '') {
  state.error = true;
  state.isWaiting = false;
  state.message = message;
}
