import * as React from 'react';
import { LabeledTextField } from '../../../app/components/Forms';

export interface FormProps {
  onChange: Function;
  onSubmit: Function;
  layoutTemplate: LayoutTemplate | null | undefined;
  actions?: React.ReactNode;
  children?: React.ReactNode;
}

export const Form: React.FC<FormProps> = (props: FormProps) => {
  const { layoutTemplate } = props;
  const onSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    props.onSubmit(null);
  };

  const onChange = (event: React.FormEvent<HTMLInputElement>) =>
    props.onChange(event.currentTarget.name, event.currentTarget.value);

  if (!layoutTemplate) {
    return null;
  }

  return (
    <div className="o-box bg-white p-8 w-full">
      <form onSubmit={onSubmit}>
        <div className="flex space-x-4">
          <LabeledTextField
            name="name"
            label="layout-templates.name"
            defaultValue={layoutTemplate.name}
            onChange={onChange}
            required={true}
            size={48}
          />
        </div>
        {props.actions ? <div className="ml-auto flex items-center gap-x-4">{props.actions}</div> : null}
      </form>
      {props.children}
    </div>
  );
};

export default Form;
