import { useMemo } from 'react';
import { LayoutRow } from './LayoutRow';
import {CommonLayoutProps} from './LayoutComponent';

interface LayoutNodesProps extends CommonLayoutProps {
  layoutNodes: Array<LayoutNode>;
  shop: EntryShop;
}

export const LayoutNodes = (props: LayoutNodesProps) => {
  const { layoutNodes, shop, ...rest } = props;

  // Create a collection of FieldFragments based on FieldDefinitions
  const fieldFragmentsMap = useMemo(() => {
    if (shop && shop.fieldDefinitions) {
      const allFieldFragments = new Map<string, FieldInfo>();
      shop.fieldDefinitions.forEach((fieldDefinition) => {
        fieldDefinition.fieldFragments.forEach((fieldFragment) => {
          allFieldFragments.set(fieldFragment.id, { fieldDefinition, fieldFragment });
        });
      });
      return allFieldFragments;
    }
    return null;
  }, [shop]);

  if (!layoutNodes || (Array.isArray(layoutNodes) && layoutNodes.length === 0)) {
    return null;
  }
  return (
    <>
      {fieldFragmentsMap &&
        layoutNodes.map((item: LayoutNode) => (
          <LayoutRow key={item.id} data={item} fieldFragmentsMap={fieldFragmentsMap} {...rest} />
        ))}
    </>
  );
};
