import { useDispatch } from 'react-redux';
import { removeToast } from './toastSlice';
import { Trans } from 'react-i18next';

export function Toast({ type, message, id, delay }:ToastMessage) {
  const dispatch = useDispatch();
  const color = type === 'error' ? 'bg-red-400' : 'bg-blue-400';
  const icon = type === 'error' ? 'fa-exclamation-circle' : 'fa-check-circle';

  if (typeof delay === 'number') {
    setTimeout(() => {
      dispatch(removeToast(id));
    }, delay);
  }

  return (
    <div className={`text-white px-8 py-6 border-0 rounded mb-4 ${color ? color : 'bg-red-400' }`} data-toast={type}>
      <div className="flex">
        <div className="flex-shrink-0">
          <i className={`fas ${icon}`}></i>
        </div>
        <div className="ml-3">
          <Trans i18nKey={message} />
        </div>
        <div className="ml-auto pl-3">
          <button
            onClick={() => dispatch(removeToast(id))}
            className="inline-flex">
            <i className="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Toast;
