import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import shopsService from './shopsService';
import { paging } from '../../api/utils';
import { successToast } from '../toast/toastSlice';
import { handleServiceError  } from '../../api/utils';

export const findAll = createAsyncThunk<any, PageInfo|undefined, { rejectValue: string, dispatch: any }>(
  'shops/findAll',
  async (pageInfo, { rejectWithValue, dispatch }) => {
    try {
      return await shopsService().findAll(paging(pageInfo));
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const findOne = createAsyncThunk<any, string, { rejectValue: string, dispatch: any }>(
  'shops/findOne',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      return await shopsService().findOne({ id });
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const create = createAsyncThunk<any, { shop: PendingShop, cb?: Function }, { rejectValue: string, dispatch: any }>(
  'shops/create',
  async ({ shop, cb }, { rejectWithValue, dispatch }) => {
    try {
      const result: any = await shopsService().create(shop);
      if (result && result.id) {
        const newShop = await shopsService().findOne({ id: result.id });
        dispatch(successToast('api.shop.created'));
        if (cb) {
          cb(newShop);
        }
        return newShop;
      }
      return null;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const update = createAsyncThunk<any, { shop: Pick<Shop, "id" | "name">, users: User[], cb?: Function }, { rejectValue: string, dispatch: any }>(
  'shops/update',
  async ({ shop, users, cb }, { rejectWithValue, dispatch }) => {
    try {
      const updateShop = cloneDeep(shop);
      // Current api updates shop shops in seperate api call used below.
      // updateShop.users = users;
      const result:any = await shopsService().update(updateShop);
      await shopsService().attachUsers(updateShop, users);
      if (result && result.id) {
        dispatch(successToast('api.shop.updated'));
        if (cb) {
          cb(result);
        }
        return result;
      }
    } catch (err: any) {
      console.log(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);
export const updateWithFields = createAsyncThunk<any, { shop: Shop, users: User[], fields?: FieldDefinition[] | undefined, fieldFragmentVisibility: FieldFragmentVisibility[], cb?: Function }, { rejectValue: string, dispatch: any }>(
  'shops/update',
  async ({ shop, users, fields, fieldFragmentVisibility, cb }, { rejectWithValue, dispatch }) => {
    try {
      const updateShop = cloneDeep(shop);
      // Current api updates shop shops in seperate api call used below.
      updateShop.users = users;
      const result:any = await shopsService().update(updateShop);
      await shopsService().attachUsers(updateShop, users);
      if (fields) {
        await shopsService().updateFields(updateShop, fields, fieldFragmentVisibility || []);
      }
      if (result && result.id) {
        dispatch(successToast('api.shop.updated'));
        if (cb) {
          cb(result);
        }
        return result;
      }
    } catch (err: any) {
      console.log(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const attachUsers = createAsyncThunk<any, { shop: Shop, users: User[] }, { rejectValue: string, dispatch: any }>(
  'shops/attachUsers',
  async ({ shop, users }, { rejectWithValue, dispatch }) => {
    try {
      return await shopsService().attachUsers(shop, users);
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const updateFields = createAsyncThunk<any, { shop: Shop, fields: FieldDefinition[], fieldFragmentVisibility: FieldFragmentVisibility[] }, { rejectValue: string, dispatch: any }>(
  'shops/updateFields',
  async ({ shop, fields, fieldFragmentVisibility }, { rejectWithValue, dispatch }) => {
    try {
      return await shopsService().updateFields(shop, fields, fieldFragmentVisibility || []);
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const archive = createAsyncThunk<any, ObjectWithId, { rejectValue: string, dispatch: any }>(
  'shops/archive',
  async (shop, { rejectWithValue, dispatch }) => {
    try {
      await shopsService().archive(shop);
      dispatch(successToast('api.shop.archived'));
      return null;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const unarchive = createAsyncThunk<any, ObjectWithId, { rejectValue: string, dispatch: any }>(
  'shops/unarchive',
  async (shop, { rejectWithValue, dispatch }) => {
    try {
      await shopsService().unarchive(shop);
      dispatch(successToast('api.shop.unarchived'));
      return null;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const findArchived = createAsyncThunk<any, PageInfo|undefined, { rejectValue: string, dispatch: any }>(
  'shops/findArchived',
  async (_pageInfo, { rejectWithValue, dispatch }) => {
    try {
      return await shopsService().findArchived();
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);


export const duplicate = createAsyncThunk<any, ObjectWithId, { rejectValue: string, dispatch: any }>(
  'shops/duplicate',
  async (shop, { rejectWithValue, dispatch }) => {
    try {
      const result = await shopsService().duplicate(shop);
      return result;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

