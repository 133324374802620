import { FC } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { verbose } from '../constants';

interface ComponentProps {
  data: SideBarData;
  labels: Array<LabelItem>;
}

export const SideBarItem: FC<ComponentProps> = ({ data, labels }) => {
  const label =
    data.component.type === 'FIELD_FRAGMENT'
      ? labels.find((item) => item.key === data.component.id)?.label
      : data.component.id;

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: 'SIDEBAR_ITEM',
      item: data,
      collect: (monitor: DragSourceMonitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
      canDrag: () => data.enabled,
    }),
    [data],
  );

  return (
    <div
      className={`text-base sideBarItem sideBarItem-${data.enabled ? 'enabled' : 'disabled text-gray-400'}`}
      ref={drag}
      style={{ opacity }}
    >
      <span>{label}</span>
      {verbose > 1 && <span className="verbose-1">{data.component.id}</span>}
      {verbose > 0 && <span className="verbose-1">{data.enabled ? 'T' : 'F'}</span>}
    </div>
  );
};
