import { request } from '../../api';
import config from '../../config';

const baseUrl = `${config.baseUrl}/layout-templates`;

export const layoutTemplatesService = () => {
  return {
    findAll: () => request(`${baseUrl}`, 'get'),
    findOne: ({ id }: { id: string }) => request(`${baseUrl}/${id}`, 'get'),
    findOneWithShops: ({ id }: { id: string }) => request(`${baseUrl}/${id}/shops`, 'get'),
    update: (layoutTemplate: Partial<LayoutTemplate>) =>
      request(`${baseUrl}/${layoutTemplate.id}`, 'patch', layoutTemplate),
    create: (layoutTemplate: Partial<LayoutTemplate>) => request(`${baseUrl}`, 'post', layoutTemplate),
  };
};
