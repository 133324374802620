import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LinkButton } from '../../app/components/LinkButton';
import { ToolBar } from '../../app/components/ToolBar';
import { ToolBarSearch } from '../../app/components/ToolBarSearch';
import { Trans } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { findAll } from './actions';
import { selectCustomFields, selectErrorMessage } from './customFieldsSlice';
import { Feedback } from '../../app/components/Feedback';
import { useBoolean } from 'ahooks';
import { InfoDrawer } from './components/InfoDrawer';
import { Button } from 'antd';

interface ActionsProps {
  onSearch: Function;
}
const Actions = (props: ActionsProps) => (
  <>
    <ToolBarSearch placeholder="custom-fields.search" onChange={props.onSearch} />
    <LinkButton to="/custom-fields/add" label="custom-fields.activity.add" />
  </>
);

export function Overview() {
  const fields = useAppSelector(selectCustomFields);
  const errorMessage = useAppSelector(selectErrorMessage);
  const dispatch = useAppDispatch();
  const [showInfoDrawer, { toggle: toggleInfoDrawer, setFalse: hideInfoDrawer }] = useBoolean(false);
  const [localFields, setLocalFields] = useState<FieldDefinition[]>([]);
  const [localField, setLocalField] = useState<FieldDefinition>();

  // Filter local fields
  const filterFields = useCallback(
    (value: string) => {
      if (!fields) {
        return;
      }
      if (value && value.length < 2) {
        return setLocalFields(fields);
      }
      setLocalFields(fields.filter((field) => field.name.en.toLowerCase().includes(value)));
    },
    [fields]
  );

  // Fetch fields to store
  useEffect(() => {
    dispatch(findAll());
  }, [dispatch]);

  // Copy local fields from store
  useEffect(() => {
    if (!fields) {
      return;
    }
    setLocalFields(fields);
  }, [fields]);

  const showInfoDrawerFor = (fieldDefinition:FieldDefinition) => {
    setLocalField(fieldDefinition);
    toggleInfoDrawer();
  }

  return (
    <>
      {/** */}
      <ToolBar title="custom-fields.title" actions={<Actions onSearch={filterFields} />} />
      {/** */}
      <div className="o-table">
        <table>
          <thead>
            <tr>
              <td>
                <Trans i18nKey="name" />
              </td>
              <td>
                <Trans i18nKey="custom-fields.type" />
              </td>
              <td>
                <Trans i18nKey="custom-fields.state" />
              </td>
              <td>
                <Trans i18nKey="assigned shops" />
              </td>
            </tr>
          </thead>
          <tbody>
            {localFields.map((field) => (
              <tr className="o-table-row" key={field.id}>
                <td>
                  <Link to={`/custom-fields/edit/${field.id}`}>{field.name.en || '-'}</Link>
                </td>
                <td>
                  <Trans i18nKey={`custom-fields.type.${field.type}`} />
                </td>
                <td>
                  <Trans i18nKey={`custom-fields.state.${field.active ? 'ACTIVE' : 'INACTIVE'}`} />
                </td>
                <td>
                  <Button type="link" onClick={() => {showInfoDrawerFor(field)}}> {field.linkedShopsCount || '0'}</Button>
                </td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Feedback errorMessage={errorMessage} />

      {showInfoDrawer && <InfoDrawer onClose={hideInfoDrawer} fieldDefinition={localField} />}
    </>
  );
}
