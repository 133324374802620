import React, { useState, useCallback, CSSProperties, useImperativeHandle, forwardRef, useEffect } from 'react';
import { LayoutDropZone } from './LayoutDropZone';
import { TrashDropZone } from './TrashDropZone';
import { Row } from './Row';
import { emptyTemplate } from './data';
import {
  handleMoveWithinParent,
  handleMoveToDifferentParent,
  handleMoveSidebarComponentIntoParent,
  handleRemoveItemFromLayout,
  uuid,
} from './helpers';

import { SideBar } from './SideBar';
import { findNodeWithId, getFieldFragmentIds } from './utils';
import './style.css';
import { cloneDeep } from 'lodash';

const sidebarTrashStyle: CSSProperties = {
  marginTop: 48,
  marginBottom: 48,
};

// Define the type for the exposed methods
export interface EditorRef {
  getLayoutTemplate: () => Array<LayoutNode> | null;
}

interface EditorProps {
  sidebarItems: Array<SideBarData>;
  labels: Array<LabelItem>;
  nodes: Array<LayoutNode> | null;
}

export const Editor = forwardRef<EditorRef, EditorProps>(({ sidebarItems, labels, nodes }, ref) => {
  const [layout, setLayout] = useState<Array<LayoutNode>>(nodes || emptyTemplate);
  const [localSidebarItems, setLocalSidebarItems] = useState<Array<SideBarData>>(sidebarItems);

  useImperativeHandle(ref, () => ({
    getLayoutTemplate: () => layout,
  }));

  useEffect(() => {
    if (nodes) {
      setLayout(nodes);
    } else {
      setLayout(emptyTemplate);
    }
  }, [nodes]);

  const handleDropToTrashBin = useCallback(
    (_dropZone: any, item: any) => {
      const splitItemPath = item.path.split('-');
      setLayout(handleRemoveItemFromLayout(layout, splitItemPath));
    },
    [layout],
  );

  const handleDrop = useCallback(
    (dropZone: any, item: any) => {
      const splitDropZonePath = dropZone.path.split('-');
      const pathToDropZone = splitDropZonePath.slice(0, -1).join('-');

      // DROP SIDEBAR ITEM
      if (item.type === 'SIDEBAR_ITEM') {
        setLayout(
          handleMoveSidebarComponentIntoParent(layout, splitDropZonePath, {
            id: item.component.type === 'FIELD_FRAGMENT' ? item.component.id : uuid(),
            type: item.component.type,
          }),
        );
        return;
      }

      const newItem = { id: item.id, type: item.type } as LayoutNode;
      if (item.type === 'COLUMN') {
        newItem.children = item.children;
      }

      // move down here since sidebar items dont have path
      const splitItemPath = item.path.split('-');
      const pathToItem = splitItemPath.slice(0, -1).join('-');

      // 2. Pure move (no create)
      if (splitItemPath.length === splitDropZonePath.length) {
        // 2.a. move within parent
        if (pathToItem === pathToDropZone) {
          setLayout(handleMoveWithinParent(layout, splitDropZonePath, splitItemPath));
          return;
        }

        // 2.b. OR move different parent
        // TODO FIX columns. item includes children
        setLayout(handleMoveToDifferentParent(layout, splitDropZonePath, splitItemPath, newItem));
        return;
      }

      // 3. Move + Create
      setLayout(handleMoveToDifferentParent(layout, splitDropZonePath, splitItemPath, newItem));
    },
    [layout],
  );

  // Update sidebar item availability
  useEffect(() => {
    const ids = getFieldFragmentIds(layout);
    setLocalSidebarItems(
      sidebarItems.map((sidebarItem) => ({ ...sidebarItem, enabled: !ids.includes(sidebarItem.component.id) })),
    );
  }, [layout, sidebarItems]);

  const onRowLabelChange = (nodeId: string, label: string) => {
    const layoutClone = cloneDeep(layout);
    const node = findNodeWithId(layoutClone, nodeId);
    if (node) {
      node.name = label;
      setLayout(layoutClone);
    }
  };

  return (
    <div className="flex">
      <SideBar sidebarItems={localSidebarItems} labels={labels}>
        <div style={sidebarTrashStyle}>
          <TrashDropZone layout={layout} onDrop={handleDropToTrashBin} />
        </div>
      </SideBar>
      <div className="editorContainer">
        <div className="editor">
          {layout.map((row, index) => {
            const currentPath = `${index}`;
            return (
              <React.Fragment key={row.id}>
                {/* DROPZONE betwwn each row */}
                <LayoutDropZone
                  data={{
                    path: currentPath,
                    childrenCount: layout.length,
                  }}
                  onDrop={handleDrop}
                />
                {/* ROW */}
                <Row
                  key={row.id}
                  data={row}
                  handleDrop={handleDrop}
                  path={currentPath}
                  labels={labels}
                  onLabelChange={onRowLabelChange}
                />
              </React.Fragment>
            );
          })}
          <LayoutDropZone
            data={{
              path: `${layout.length}`,
              childrenCount: layout.length,
            }}
            onDrop={handleDrop}
            isLast
          />
        </div>
      </div>
    </div>
  );
});
