import { Select } from 'antd';
import { useMemo } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { selectLayoutTemplates } from '../../layout-templates/layoutTemplatesSlice';
import { sortBy, uniqueId } from 'lodash';
import { Trans } from 'react-i18next';

interface EditLinkedLayoutTemplateProps {
  onChange: Function;
  shopLayoutTemplate: Partial<ShopLayoutTemplate> | null | undefined;
}

export const EditLinkedLayoutTemplate: React.FC<EditLinkedLayoutTemplateProps> = ({
  onChange,
  shopLayoutTemplate,
}: EditLinkedLayoutTemplateProps) => {
  const layoutTemplates = useAppSelector(selectLayoutTemplates);
  const localId = useMemo(() => {
    return uniqueId('labeled-input');
  }, []);

  // Create list of LayoutTemplates
  const options: Array<SelectItem> = useMemo(() => {
    if (Array.isArray(layoutTemplates)) {
      return sortBy(
        layoutTemplates
          .filter((layoutTemplate) => layoutTemplate.name !== '')
          .map((layoutTemplate) => ({
            value: layoutTemplate.id,
            label: layoutTemplate.name,
          })),
        'label'
      );
    }
    return [];
  }, [layoutTemplates]);

  const onChangeLocal = (value: string | undefined) => {
    if (typeof value === 'undefined') {
      onChange({
        ...shopLayoutTemplate,
        layoutTemplate: null,
        templateType: 'USE_NAMED_TEMPLATE',
      });
    } else {
      onChange({
        ...shopLayoutTemplate,
        layoutTemplate: { id: value },
        templateType: 'USE_NAMED_TEMPLATE',
      });
    }
  };

  // "Select" element only sets default value if we use this memo
  const defaultValue: string|undefined = useMemo(() => {
    if (options.length === 0) {
      return undefined;
    }
    // shopLayoutTemplate already has a value
    if (shopLayoutTemplate && options) {
      return options.find((item) => item.value === shopLayoutTemplate?.layoutTemplate?.id)?.value || '';
    }
    // shopLayoutTemplate has no value selected
    if (typeof shopLayoutTemplate === 'undefined' && options) {
      return '';
    }
    // wait for shopLayoutTemplate
    return undefined;
  }, [shopLayoutTemplate, options]);


  return (
    <>
      <div className="mb-4 mr-4">
        <label htmlFor={localId}>
          <Trans i18nKey="layout-templates.select" />
          {typeof defaultValue === 'string' && (
            <Select
              style={{ width: '100%' }}
              options={options}
              onChange={onChangeLocal}
              defaultValue={defaultValue}
              allowClear={true}
            />
          )}
        </label>
      </div>
    </>
  );
};
