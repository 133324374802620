import { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ToolBar } from '../../app/components/ToolBar';
import { ToolBarSearch } from '../../app/components/ToolBarSearch';
import { LinkButton } from '../../app/components/LinkButton';
import { findAll } from './actions';
import { selectShops } from './shopsSlice';
import { ActionsDropdown } from './components/ActionsDropdown';
import { getFirstShopLayoutTemplate } from './util';

interface ActionsProps {
  onSearch: Function;
}
const Actions = (props: ActionsProps) => (
  <>
    <Link to="/shops/archived">
      <Trans i18nKey="shops.archived" />
    </Link>
    <ToolBarSearch placeholder="shops.search" onChange={props.onSearch} />
    <LinkButton to="/shops/add" label="shops.add" />
  </>
);

interface ShopLayoutTemplateProps {
  shop: Shop;
}
const ShopLayoutTemplate = ({ shop }: ShopLayoutTemplateProps) => {
  const layoutTemplate = getFirstShopLayoutTemplate(shop.shopLayoutTemplates)?.layoutTemplate;
  if (!layoutTemplate) {
    return null;
  }
  return (
    <Link to={`/layout-templates/edit/${layoutTemplate.id}`}>
      <Trans i18nKey={layoutTemplate?.name || '-'} />
    </Link>
  );
};

export function Overview() {
  const shops = useAppSelector(selectShops);
  const dispatch = useAppDispatch();
  const [localShops, setLocalShops] = useState<Shop[]>([]);

  useEffect(() => {
    dispatch(findAll());
  }, [dispatch]);

  // Filter local shops
  const filterShops = useCallback(
    (value: string) => {
      if (!shops) {
        return;
      }
      if (value && value.length < 2) {
        return setLocalShops(shops);
      }
      setLocalShops(shops.filter((item) => item.name.toLowerCase().includes(value)));
    },
    [shops]
  );

  // Copy shops for local use
  useEffect(() => {
    if (!shops) {
      return;
    }
    setLocalShops(shops);
  }, [shops]);

  return (
    <>
      <ToolBar title="shops.title" actions={<Actions onSearch={filterShops} />} />
      <div className="o-table">
        <table>
          <thead>
            <tr>
              <td>
                <Trans i18nKey="name" />
              </td>
              <td>
                <Trans i18nKey="assigned users" />
              </td>
              <td>
                <Trans i18nKey="assigned activities" />
              </td>
              <td>
                <Trans i18nKey="assigned template" />
              </td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {localShops.map((c) => (
              <tr className="o-table-row" key={c.id}>
                <td>
                  <Link to={`/shops/edit/${c.id}`}>{c.name}</Link>
                </td>
                <td>{c.linkedUserCount || '0'}</td>
                <td>{c.linkedFieldCount || '0'}</td>
                <td>
                  <ShopLayoutTemplate shop={c} />
                </td>
                <td>
                  <ActionsDropdown shop={c} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
