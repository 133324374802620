export function fieldFragmentlabel(fieldFragment: FieldFragment): string {
  return [fieldFragment.fieldDefinition?.name?.en, fieldFragment.name.en].join('/');
}

export function fieldFragmentlabels(fieldFragmentsList: Array<FieldFragment>): Array<LabelItem> {
  return fieldFragmentsList.map((fieldFragment) => ({
    label: fieldFragmentlabel(fieldFragment),
    key: fieldFragment.id,
  }));
}

export function fieldFragmentlabel2(fieldFragmentData: FieldFragmentData): string {
  return [fieldFragmentData.fieldDefinition?.name?.en, fieldFragmentData.fieldFragment.name.en].join('/');
}

export function fieldFragmentlabels2(fieldFragmentData: Array<[string, FieldFragmentData]>): Array<LabelItem> {
  return fieldFragmentData.map((fieldFragmentData) => ({
    label: fieldFragmentlabel2(fieldFragmentData[1]),
    key: fieldFragmentData[1].fieldFragment.id,
  }));
}
