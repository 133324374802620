import { uniqueid } from './utils';

export const emptyTemplate: Array<LayoutNode> = [
  {
    type: 'ROW',
    id: uniqueid('ROW'),
    name: 'row1',
    children: [
      {
        type: 'COLUMN',
        id: uniqueid('COL'),
        children: [],
      },
    ],
  },
];
