import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToolBar } from '../../app/components/ToolBar';
import { Form } from './components/Form';
import { selectIsWaiting } from './shopsSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Nav } from './components/ToolBarNav';
import { Actions } from './components/ToolBarActions';
import { create } from './actions';
import {defaultShop} from './util';
import {cloneDeep} from 'lodash';

export function AddShop() {
  const dispatch = useAppDispatch();
  const isWaiting = useAppSelector(selectIsWaiting);
  const navigate = useNavigate();

  const [localeShop, setLocaleShop] = useState<Shop>(defaultShop());

  const updateShop = useCallback((key: string, value: string) => {
    setLocaleShop({ ...localeShop, [key]: value });
  }, [localeShop]);

  const onSubmit = useCallback(async () => {
    const newShop = cloneDeep(localeShop);
    newShop.users = [];
    newShop.fieldDefinitions = [];
    newShop.fieldFragmentVisibility = [];
    try {
      const result = await dispatch(create({ shop: newShop }));
      // Stay on this route, toast with message should be visible.
      if (result.meta.requestStatus === "rejected") {
        return;
      }
      if (result.payload.id) {
        return navigate(`/shops/edit/${result.payload.id}`);
      }
      navigate('/shops');
    } catch(err) {
    }
  }, [dispatch, navigate, localeShop]);

  return (
    <>
      <ToolBar title='shops.add' nav={<Nav />} actions={<Actions onSave={onSubmit} disabled={isWaiting} />} />
      <div className='flex space-x-2 justify-between'>
        <Form onSubmit={onSubmit} shop={localeShop} onChange={updateShop} />
      </div>
    </>
  )
}
