import { CommonLayoutProps, LayoutComponent } from './LayoutComponent';

interface ComponentProps extends CommonLayoutProps {
  data: LayoutNode;
  fieldFragmentsMap: Map<string, FieldInfo>;
}

export const LayoutColumn = ({ data, fieldFragmentsMap, ...rest }: ComponentProps) => {
  return (
    <div className="flex flex-col basis-full gap-2">
      {data.children.map((component) => (
        <LayoutComponent
          key={component.id}
          data={component}
          fieldFragmentsMap={fieldFragmentsMap}
          {...rest}
        />
      ))}
    </div>
  );
};
