import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { handleServiceError } from '../../api/utils';
import { successToast } from '../toast/toastSlice';
import { shopLayoutTemplatesService } from './shopLayoutTemplatesService';

export const findAllShopLayoutTemplates = createAsyncThunk<any, undefined, { rejectValue: string; dispatch: any }>(
  'shopLayoutTemplates/findAll',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return await shopLayoutTemplatesService().findAll();
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const findOne = createAsyncThunk<any, string, { rejectValue: string; dispatch: any }>(
  'layoutTemplates/findOne',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      return await shopLayoutTemplatesService().findOne({ id });
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const createShopLayoutTemplate = createAsyncThunk<
  any,
  { shopLayoutTemplate: Partial<ShopLayoutTemplate>; cb?: Function },
  { rejectValue: string; dispatch: any }
>('layoutTemplates/create', async ({ shopLayoutTemplate, cb }, { rejectWithValue, dispatch }) => {
  try {
    const result = await shopLayoutTemplatesService().create(shopLayoutTemplate);
    if (cb) {
      cb(result);
    }
    return result;
  } catch (err: any) {
    console.error(err);
    handleServiceError(err, dispatch);
    return rejectWithValue(err);
  }
});

export const updateShopLayoutTemplates = createAsyncThunk<
  any,
  { shopLayoutTemplate: Partial<ShopLayoutTemplate>; cb?: Function },
  { rejectValue: string; dispatch: any }
>('layoutTemplates/update', async ({ shopLayoutTemplate, cb }, { rejectWithValue, dispatch }) => {
  try {
    if (!shopLayoutTemplate?.id) {
      throw new Error('shopLayoutTemplate has no id');
    }
    const result: any = await shopLayoutTemplatesService().update(shopLayoutTemplate);
    if (result && result.id) {
      dispatch(successToast('api.layoutTemplate.updated'));
      if (cb) {
        cb(result);
      }
      return result;
    }
  } catch (err: any) {
    console.log(err);
    handleServiceError(err, dispatch);
    return rejectWithValue(err);
  }
});

export const cloneDefaultLayoutTemplate = createAsyncThunk<
  any,
  { shopLayoutTemplate: ShopLayoutTemplate; cb?: Function },
  { rejectValue: string; dispatch: any }
>('layoutTemplates/cloneDefaultLayoutTemplate', async ({ shopLayoutTemplate, cb }, { rejectWithValue, dispatch }) => {
  try {
    console.log('TODO clone default template into shop template', shopLayoutTemplate, cb);
    // return await shopLayoutTemplatesService().cloneDefaultLayoutTemplate();
    return false;
  } catch (err: any) {
    console.error(err);
    handleServiceError(err, dispatch);
    return rejectWithValue(err);
  }
});

export const findByType = createAsyncThunk<any, ShopTemplateType, { rejectValue: string; dispatch: any }>(
  'layoutTemplates/findByType',
  async (shopTemplateType, { rejectWithValue, dispatch }) => {
    try {
      return await shopLayoutTemplatesService().findByType(shopTemplateType);
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const findDefaultTemplate = createAsyncThunk<any, undefined, { rejectValue: string; dispatch: any }>(
  'layoutTemplates/findDefaultTemplate',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await shopLayoutTemplatesService().findByType('DEFAULT_TEMPLATE');
      if (Array.isArray(result)) {
        return result.pop() as ShopLayoutTemplate;
      }
      return null;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const resetTemplate = createAction('layoutTemplates/reset');
