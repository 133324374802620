import { FieldFragment } from './FieldFragment';
import {CommonLayoutProps} from './LayoutComponent';

interface LayoutFieldFragmentProps extends CommonLayoutProps {
  fieldFragmentId: string;
  fieldFragment: FieldFragment | undefined;
  fieldDefinition: FieldDefinition | undefined;
}

export const LayoutFieldFragment = (props: LayoutFieldFragmentProps) => {
  const { fieldFragment, fieldFragmentId, fieldDefinition, ...rest } = props;
  const labelPrefix = fieldDefinition?.name.current ? (
    <strong className="mr-2">{fieldDefinition?.name.current}</strong>
  ) : (
    ''
  );

  if (fieldFragment) {
    return <FieldFragment key={fieldFragmentId} fieldFragment={fieldFragment} labelPrefix={labelPrefix} {...rest} />;
  }
  return null;
};

