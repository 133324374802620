import { Route, Routes } from 'react-router-dom';
import { Edit } from './Edit';
import { LayoutTemplatesOverview } from './Overview';
import { Add } from './Add';

export const LayoutTemplateRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<LayoutTemplatesOverview />} />
      <Route path="/edit/:id" element={<Edit />} />
      <Route path="/add" element={<Add />} />
    </Routes>
  );
};
