import { Route, Routes } from "react-router-dom";
import { EditEntry } from './Edit';
import {EditEntryWithTemplate} from "./EditWithTemplate";
export const EntriesRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/shop-layout-template/:shopId" element={<EditEntryWithTemplate />} />
      <Route path="/shop/:shopId" element={<EditEntry />} />
    </Routes>
  );
}
