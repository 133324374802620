import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ToolBar } from '../../app/components/ToolBar';
import { findOne, archive, update } from './actions';
import { updateShopLayoutTemplates, createShopLayoutTemplate } from '../shop-layout-templates/actions';
import { cloneDeep } from 'lodash';
import { Form } from './components/Form';
import { Button } from 'antd';
import { selectShop, selectIsWaiting } from './shopsSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { EditLinkedUsers } from '../../app/components/EditLinkedUsers';
import { EditLinkedFields } from '../../app/components/EditLinkedFields';
import { Nav } from './components/ToolBarNav';
import { Actions } from './components/ToolBarActions';
import { Trans } from 'react-i18next';
import { Popconfirm } from '../../app/components/PopConfirm';
import { useMap } from 'ahooks';
import { EditLinkedLayoutTemplate } from './components/EditLinkedLayoutTemplate';
import { ListLinkedFields } from '../../app/components/ListLinkedFields';
import { findAllLayoutTemplates } from '../layout-templates/layoutTemplateActions';
import {getFirstShopLayoutTemplate} from './util';

const showEditLinkedFields = false;

export function EditShop() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const shop = useAppSelector(selectShop);
  const isWaiting = useAppSelector(selectIsWaiting);
  const navigate = useNavigate();

  const [localeShop, setLocaleShop] = useState<Shop | null>(null);
  const [refreshLocalShop, setRefreshLocalShop] = useState<boolean>(true);
  const [localeLinkedUsers, setLocaleLinkedUsers] = useState<User[]>([]);
  const [localeLinkedFields, setLocaleLinkedFields] = useState<FieldDefinition[]>([]);
  // Map of FieldFragmentVisibility.FieldFragment.id as key and FieldFragmentVisibility.visibility as value
  const [
    localeFieldFragmentVisibilityMap,
    { setAll: setAllLocaleFieldFragmentVisibilityMap, set: setLocaleFieldFragmentVisibility },
  ] = useMap<string, VisibilityType>([]);

  // Fetch shop
  useEffect(() => {
    if (typeof id !== 'string' || refreshLocalShop === false) {
      return;
    }
    dispatch(findOne(id));
    dispatch(findAllLayoutTemplates()); // get a list of all layout-templates
  }, [dispatch, id, refreshLocalShop]);

  // Copy Shop into local Shop for edit
  useEffect(() => {
    if (typeof id !== 'string') {
      return;
    }
    // Only update locale Shop once after receiving data
    if (shop !== null && id === `${shop.id}` && (localeShop === null || refreshLocalShop === true)) {
      setLocaleShop(cloneDeep(shop));
      setLocaleLinkedUsers(cloneDeep(shop.users || []));
      setLocaleLinkedFields(cloneDeep(shop.fieldDefinitions || []));
      setAllLocaleFieldFragmentVisibilityMap(
        cloneDeep(shop.fieldFragmentVisibility || [])
          .filter((item: FieldFragmentVisibility) => typeof item.fieldFragment?.id !== 'undefined')
          .map((item: FieldFragmentVisibility) => [item.fieldFragment.id, item.visibility])
      );
      setRefreshLocalShop(false);
    }
  }, [shop, localeShop, id, setAllLocaleFieldFragmentVisibilityMap, refreshLocalShop]);

  const updateShop = useCallback(
    (key: string, value: string) => {
      if (localeShop === null) {
        return;
      }
      setLocaleShop({ ...localeShop, [key]: value });
    },
    [localeShop]
  );

  const linkUsers = useCallback(
    (users: User[], linked: boolean) => {
      // Add user to linked users
      if (linked) {
        setLocaleLinkedUsers([...localeLinkedUsers, ...users]);
      } else {
        // Remove user from linked users
        const newList = localeLinkedUsers.reduce((acc, curr) => {
          if (users.find((user) => user.id === curr.id)) {
            return acc;
          }
          acc.push(curr);
          return acc;
        }, [] as User[]);
        setLocaleLinkedUsers(newList);
      }
    },
    [localeLinkedUsers]
  );

  const linkField = useCallback(
    (field: FieldDefinition, linked: boolean) => {
      // Add field to linked fields
      if (linked) {
        if (localeLinkedFields.find((curr) => curr.id === field.id)) {
          return;
        }
        setLocaleLinkedFields([...localeLinkedFields, field]);
      } else {
        // Remove field from linked fields
        setLocaleLinkedFields(localeLinkedFields.filter((curr) => curr.id !== field.id));
      }
    },
    [localeLinkedFields]
  );

  const fieldFragmentVisibilityChange = useCallback(
    (fieldFragment: FieldFragment, visibility: VisibilityType) => {
      setLocaleFieldFragmentVisibility(fieldFragment.id, visibility);
    },
    [setLocaleFieldFragmentVisibility]
  );

  const onSave = useCallback(async () => {
    if (localeShop === null) {
      return;
    }

    // Await and update call order is important here.
    // First do ShopTemplateType updates and then Shop update because
    // the Shop Update response will contain the Shop with all relations and the response will be put in the redux
    // store.
    const shopLayoutTemplate = getFirstShopLayoutTemplate(localeShop.shopLayoutTemplates);
    if (shopLayoutTemplate) {
      if (shopLayoutTemplate.id) {
        await dispatch(updateShopLayoutTemplates({ shopLayoutTemplate: {
          shop: { id: localeShop.id },
          ...shopLayoutTemplate,
        }}));
      } else {
         await dispatch(
           createShopLayoutTemplate({
             shopLayoutTemplate: {
               shop: { id: localeShop.id },
               ...shopLayoutTemplate,
             },
           })
         );
      }
    }

    // Update shop
    await dispatch(
      update({
        shop: {
          id: localeShop.id,
          name: localeShop.name,
        },
        users: [...localeLinkedUsers],
      })
    );
    // Refresh local shop
    setRefreshLocalShop(true);
  }, [dispatch, localeShop, localeLinkedUsers]);

  const archiveShop = useCallback(async () => {
    if (typeof id !== 'string') {
      return;
    }
    await dispatch(archive({ id }));
    navigate('/shops');
  }, [dispatch, id, navigate]);

  const linkedLayoutTemplateChange = useCallback(
    // BBB
    async (shopLayoutTemplate: ShopLayoutTemplate) => {
      if (localeShop === null) {
        return;
      }
      setLocaleShop({ ...localeShop, shopLayoutTemplates: [shopLayoutTemplate] });
    },
    [localeShop]
  );

  return (
    <>
      <ToolBar title="shops.edit" nav={<Nav />} actions={<Actions onSave={onSave} disabled={isWaiting} />} />
      <div className="flex space-x-2 justify-between">
        <div className="o-box w-full">
          <Form
            onSubmit={onSave}
            shop={localeShop}
            onChange={updateShop}
            actions={
              <Popconfirm title="shops.archive" onConfirm={archiveShop}>
                <Button type="primary">
                  <Trans i18nKey="shops.archive" />
                </Button>
              </Popconfirm>
            }
          ></Form>

          <div className="o-box bg-white p-8 mt-4">
            {/* LAYOUT TEMPLATE */}
            <div className="mb-4">
              <h2 className="text-2xl font-bold mb-2">
                {' '}
                <Trans i18nKey={'shop.layout-template'} />
              </h2>
              <EditLinkedLayoutTemplate
                onChange={linkedLayoutTemplateChange}
                shopLayoutTemplate={getFirstShopLayoutTemplate(localeShop?.shopLayoutTemplates)}
              />
            </div>

            {/* LIST LINKED FIELDS */}
            <ListLinkedFields assigendFields={isWaiting ? null : localeLinkedFields} />

            {/* EDIT LINKED FIELDS */}
            {showEditLinkedFields && (
              <EditLinkedFields
                onChange={linkField}
                onVisibilityChange={fieldFragmentVisibilityChange}
                assigendFields={isWaiting ? null : localeLinkedFields}
                fieldFragmentVisibilityMap={localeFieldFragmentVisibilityMap}
              />
            )}
          </div>
        </div>

        {/* EDIT LINKED USERS */}
        <EditLinkedUsers onChange={linkUsers} assigendUsers={isWaiting ? null : localeLinkedUsers} />
      </div>
    </>
  );
}
