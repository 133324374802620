import {uniqueId} from 'lodash';

export function defaultShop(): Shop {
  return {
    id: uniqueId(),
    name: '',
    users: [],
    fieldFragmentVisibility: [],
    fieldDefinitions: [],
    shopLayoutTemplates: null,
  };
}

export function getFirstShopLayoutTemplate(
  shopLayoutTemplates: Array<Partial<ShopLayoutTemplate>> | null | undefined
): Partial<ShopLayoutTemplate> | null {
  if (Array.isArray(shopLayoutTemplates)) {
    return shopLayoutTemplates[0];
  }
  return null;
}
