import { uniq } from 'lodash';
import uniqueId from 'lodash/uniqueId';

export function uniqueid(prefix: string = ''): string {
  if (prefix.split('-').length > 0) {
    console.warn("Avoid using '-' in ids.");
  }
  return uniqueId(prefix);
}

export function getFieldFragmentIds(items: Array<LayoutNode> | null): string[] {
  const result: string[] = [];
  if (!Array.isArray(items)) {
    return result;
  }

  function collectIds(nodes: LayoutNode[]) {
    for (const node of nodes) {
      if (node.type === 'FIELD_FRAGMENT' || node.type === 'COMPONENT') {
        result.push(node.id);
      }
      if (node.children && node.children.length > 0) {
        collectIds(node.children);
      }
    }
  }

  collectIds(items);
  return uniq(result);
}

export function findNodeWithId(items: Array<LayoutNode> | null, nodeId: string): LayoutNode | null {
  let result: LayoutNode | null = null;

  if (!Array.isArray(items)) {
    return result;
  }

  function collectIds(nodes: LayoutNode[]) {
    for (const node of nodes) {
      if (node.id === nodeId) {
        result = node;
        break;
      }
      if (node.children && node.children.length > 0) {
        collectIds(node.children);
      }
    }
  }

  collectIds(items);
  return result;
}
