import { createSlice } from '@reduxjs/toolkit';
import { findAllLayoutTemplates, findOneLayoutTemplate, resetLayoutTemplate } from './layoutTemplateActions';
import { getResponseError } from '../../api';
import { RootState } from '../../app/store';
import { fulfilled, pending, rejected } from '../../app/storeUtils';

interface layoutTemplateState extends StoreState {
  layoutTemplates: LayoutTemplate[] | null; // all shop layout templates
  layoutTemplate: LayoutTemplate | null; // The layoutTemplate to edit
}

const initialState: layoutTemplateState = {
  error: false,
  isWaiting: false,
  layoutTemplates: null,
  layoutTemplate: null,
  message: '',
};

export const layoutTemplates = createSlice({
  name: 'layoutTemplates',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      // GET ALL LAYOUT-TEMPLATES
      .addCase(findAllLayoutTemplates.pending, (state) => {
        pending(state);
        state.layoutTemplates = null;
      })
      .addCase(findAllLayoutTemplates.fulfilled, (state, action) => {
        fulfilled(state);
        state.layoutTemplates = action.payload;
      })
      .addCase(findAllLayoutTemplates.rejected, (state, action) => {
        rejected(state, getResponseError(action));
        state.layoutTemplates = null;
      })
      // GET SINGLE LAYOUT-TEMPLATE
      .addCase(findOneLayoutTemplate.pending, (state) => {
        pending(state);
        state.layoutTemplate = null;
      })
      .addCase(findOneLayoutTemplate.fulfilled, (state, action) => {
        fulfilled(state);
        state.layoutTemplate = action.payload;
      })
      .addCase(findOneLayoutTemplate.rejected, (state, action) => {
        rejected(state, getResponseError(action));
        state.layoutTemplate = null;
      })
      //// // UPDATE LAYOUT-TEMPLATE
      //// .addCase(update.pending, (state) => {
      ////   pending(state, 'Saving Layout Template');
      //// })
      //// .addCase(update.fulfilled, (state, action) => {
      ////   fulfilled(state, 'Layout Template saved');
      ////   state.layoutTemplate = action.payload;
      //// })
      //// .addCase(update.rejected, (state, action) => {
      ////   rejected(state, getResponseError(action));
      ////   // state.layoutTemplate = null;
      //// })
      .addCase(resetLayoutTemplate, (state) => {
        state.layoutTemplate = null;
      })
      // DEFAULT
      .addDefaultCase(() => {}),
});

// SELECTORS
export const selectLayoutTemplates = (state: RootState) => state.layoutTemplates.layoutTemplates;
export const selectLayoutTemplate = (state: RootState) => state.layoutTemplates.layoutTemplate;
export const selectMessage = (state: RootState): string => state.layoutTemplates.message;
export const selectErrorMessage = (state: RootState): string =>
  state.layoutTemplates.error === true ? state.layoutTemplates.message : '';
export const selectIsWaiting = (state: RootState): boolean => state.layoutTemplates.isWaiting;

export default layoutTemplates.reducer;
