import { request } from '../../api';
import config from '../../config';

const baseUrl = `${config.baseUrl}/shops-layout-templates`;

export const shopLayoutTemplatesService = () => {
  return {
    findAll: () => request(`${baseUrl}`, 'get'),
    findOne: ({ id }: { id: string }) => request(`${baseUrl}/${id}`, 'get'),
    findByType: (shopTemplateType: ShopTemplateType) => request(`${baseUrl}/by-type/${shopTemplateType}`, 'get'),
    update: (shopLayoutTemplate: Partial<ShopLayoutTemplate>) =>
      request(`${baseUrl}/${shopLayoutTemplate.id}`, 'patch', shopLayoutTemplate),
    create: (shopLayoutTemplate: Partial<ShopLayoutTemplate>) => request(`${baseUrl}`, 'post', shopLayoutTemplate),
  };
};
