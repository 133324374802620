import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import usersReducer from '../features/users/usersSlice';
import shopsReducer from '../features/shops/shopsSlice';
import entriesReducer from '../features/entries/entriesSlice';
import checkReducer from '../features/checks/checksSlice';
import balanceReducer from '../features/balances/balancesSlice';
import customFieldsReducer from '../features/custom-fields/customFieldsSlice';
import namedFieldsReducer from '../features/named-fields/namedFieldsSlice';
import shopLayoutTemplatesReducer from '../features/shop-layout-templates/shopLayoutTemplatesSlice';
import layoutTemplatesReducer from '../features/layout-templates/layoutTemplatesSlice';
import toastReducer from '../features/toast/toastSlice';
import userProfileReducer from '../features/authentication/userProfileSlice';
import authenticationSlice from '../features/authentication/authenticationSlice';

export const store = configureStore({
  reducer: {
    toast: toastReducer,
    authentication: authenticationSlice,
    userProfile: userProfileReducer,
    users: usersReducer,
    shops: shopsReducer,
    entries: entriesReducer,
    balances: balanceReducer,
    checks: checkReducer,
    customFields: customFieldsReducer,
    namedFields: namedFieldsReducer,
    shopLayoutTemplates: shopLayoutTemplatesReducer,
    layoutTemplates: layoutTemplatesReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {users: usersState, ...}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
