import { Popconfirm as PopconfirmAnt } from 'antd';
import { Trans } from 'react-i18next';
import { QuestionCircleOutlined } from '@ant-design/icons';

interface Props {
  onConfirm: Function;
  children?: React.ReactNode;
  title?: string;
  disabled?: boolean;
}

export const Popconfirm: React.FC<Props> = (props:Props) => (
  <PopconfirmAnt
    title={<Trans i18nKey={props.title || 'Are you sure?'} />}
    okText={<Trans i18nKey="Yes" />}
    cancelText={<Trans i18nKey="No" />}
    icon={<QuestionCircleOutlined style={{ color: 'red' }} /> }
    disabled={props.disabled}
    onConfirm={() => { props.onConfirm(); }}>
    {props.children}
  </PopconfirmAnt>
);

