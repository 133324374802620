import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

interface ItemProps {
  id: string;
  name: string; // FieldDefinition name (en)
}

const Item: React.FC<ItemProps> = (props: ItemProps) => (
  <div className="pt-2 pb-2">
    <Link to={`/custom-fields/edit/${props.id}`}>{props.name}</Link>
  </div>
);

interface ListProps {
  title: React.ReactNode;
  fields: FieldDefinition[] | null;
  empty?: React.ReactNode;
}

const List = (props: ListProps) => (
  <div className="mb-4">
    <h2 className="text-2xl font-bold mb-2">{props.title}</h2>
    <div className="divide-y grid grid-cols-1">
      {props.fields?.map((c: FieldDefinition) => (
        <Item key={c.id} name={c.name.en} id={c.id} />
      ))}
    </div>
    {props.fields && props.fields.length === 0 && props.empty}
  </div>
);

interface Props {
  assigendFields: FieldDefinition[] | null;
}

export const ListLinkedFields: React.FC<Props> = (props: Props) => {
  return (
    <div>
      {/** ASSIGNED FIELDS */}
      <List
        fields={props.assigendFields}
        title={
          <>
            <Trans i18nKey="Assigned activities" />
            <span className="text-base font-normal pl-2">({props.assigendFields?.length || 0})</span>
          </>
        }
        empty={
          <div>
            <Trans i18nKey="no assigned fields" />
          </div>
        }
      />
    </div>
  );
};
