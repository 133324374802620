import type { FC } from 'react';
import { SideBarItem } from './SideBarItem';

interface ComponentProps {
  children?: React.ReactNode;
  sidebarItems: Array<SideBarData>;
  labels: Array<LabelItem>;
}
export const SideBar: FC<ComponentProps> = ({ children, sidebarItems, labels }) => {
  return (
    <div className="sideBar bg-gray-default text-white">
      {sidebarItems.map((sideBarItem) => (
        <SideBarItem key={sideBarItem.id} data={sideBarItem} labels={labels} />
      ))}
      {children}
    </div>
  );
};
