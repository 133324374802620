import { LayoutColumn } from './LayoutColumn';
import { CommonLayoutProps } from './LayoutComponent';

interface ComponentProps extends CommonLayoutProps {
  data: LayoutNode;
  fieldFragmentsMap: Map<string, FieldInfo>;
}

export const LayoutRow = ({ data, fieldFragmentsMap, ...rest }: ComponentProps) => {
  return (
    <div className="p-6 bg-white">
      <h2 className="capitalize">{data.name}</h2>
      <div className="flex gap-x-8">
        {data.children.map((column) => (
          <LayoutColumn key={column.id} data={column} fieldFragmentsMap={fieldFragmentsMap} {...rest} />
        ))}
      </div>
    </div>
  );
};
