import { Drawer, Form, Input, Button, Select } from 'antd';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

interface ActionsDrawerProps {
  layoutTemplate: LayoutTemplate;
  layoutTemplates: Array<LayoutTemplate> | null;
  onClose: Function;
  onNameChange: (value: string) => void;
  onCopyLayoutTemplate: (layoutTemplateId: string) => void;
}

export const ActionsDrawer = ({
  layoutTemplate,
  layoutTemplates,
  onClose,
  onNameChange,
  onCopyLayoutTemplate,
}: ActionsDrawerProps) => {
  const [layoutTemplateLabels, setLayoutTemplateLabels] = useState<Array<SelectItem>>([]);
  const [selectedLayoutTemplateId, setSelectedLayoutTemplateId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (layoutTemplate && Array.isArray(layoutTemplates)) {
      setLayoutTemplateLabels(
        sortBy(layoutTemplates, 'name')
          // .filter(({ name }) => name !== '')
          .map(({ id, name }) => ({
            value: id,
            label: name,
            disabled: id === layoutTemplate.id,
          })),
      );
    }
  }, [layoutTemplates, layoutTemplate]);

  function onSubmit(values: any) {
    // If form input did not change values.templateName will be undefined
    onNameChange(values.templateName);
    onClose();
  }

  const copyLayoutTemplate = useCallback(() => {
    if (selectedLayoutTemplateId) {
      onCopyLayoutTemplate(selectedLayoutTemplateId);
    }
  }, [selectedLayoutTemplateId, onCopyLayoutTemplate]);

  if (!layoutTemplate) {
    return null;
  }

  return (
    <Drawer
      title={<Trans i18nKey="layout-templates.title" />}
      size="large"
      placement="right"
      open={true}
      onClose={() => {
        onClose();
      }}
    >
      <Form layout="vertical" onFinish={onSubmit}>
        <Form.Item name="templateName" label="Template Name">
          <Input style={{ width: 'calc(100% - 200px)' }} defaultValue={layoutTemplate.name} />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Update Template Name
        </Button>
      </Form>

      <hr className="mt-8 mb-8" />
      {/** select and copy other layoutTemplate */}
      <div>
        <div className="mb-2">Copy the layout from another template into this template.</div>
        <div>
          <Select style={{ width: 200 }} options={layoutTemplateLabels} onChange={setSelectedLayoutTemplateId} />
          <Button type="primary" disabled={!selectedLayoutTemplateId} onClick={copyLayoutTemplate}>
            Copy Layout
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
