import * as React from 'react';
import { Trans } from 'react-i18next';

export interface ToolBarProps {
  title: string | React.ReactNode; // string to translate or <Trans ... />
  actions?: React.ReactNode;
  showActions?: boolean;
  nav?: React.ReactNode;
};

export const ToolBar: React.FC<ToolBarProps> = ({
  title, actions, showActions = true, nav

}: ToolBarProps) => (
  <header className='c-toolbar'>
    <div className='container mx-auto pt-12 pb-6 flex items-center gap-x-4 relative'>
      {/** NAV BUTTONS */}
      {nav ? (<div className='flex items-center gap-x-4'>{nav}</div>) : null }
      {/** TITLE */}
      <h1 className='mb-0'>
        {typeof title === 'string' && <Trans i18nKey={title} />}
        {typeof title === 'object' && title}
      </h1>
      {/** ACTION BUTTONS */}
      {(actions && showActions === true) ? (<div className='ml-auto flex items-center gap-x-4'>{actions}</div>) : null }
    </div>
  </header>
)
