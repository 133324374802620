import { Loading } from '../../../app/components/Loading';
import { Button } from '../../../app/components/Forms';
import { Button as ButtonAnt } from 'antd';
import { EditOutlined } from '@ant-design/icons';

type ActionProps = {
  onSave(event: React.MouseEvent<HTMLButtonElement>): void;
  disabled: boolean;
  showButton?: boolean;
  name?: string;
  toggleActionsDrawer?: () => void;
};

export const Actions = (props: ActionProps) => {
  const couldEdit = true;
  const { disabled, toggleActionsDrawer } = props;

  return (
    <>
      <ButtonAnt type="link" onClick={toggleActionsDrawer}>
        <h2 className="whitespace-nowrap mr-4" style={{ margin: 0 }}>
          {props.name}
        </h2>
      </ButtonAnt>
      {toggleActionsDrawer && (
        <ButtonAnt type="primary" icon={<EditOutlined />} onClick={toggleActionsDrawer} shape="circle" size="large" />
      )}

      {props.showButton !== false && couldEdit && (
        <Button variant="primary" label="entries.save" onClick={props.onSave} disabled={disabled} />
      )}

      {disabled && (
        <div className="absolute" style={{ right: -30 }}>
          {' '}
          <Loading />{' '}
        </div>
      )}
    </>
  );
};
