import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { useMap } from 'ahooks';
import isISO8601 from 'validator/lib/isISO8601';
import { ToolBar } from '../../app/components/ToolBar';
import { findAll as findAllBalances } from '../balances/actions';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectEntry, selectErrorMessage, selectIsWaiting, selectLayoutTemplate, selectShops } from './entriesSlice';
import { findShops, create, createValues, findShopOnDay, findStatus, findLayoutTemplate } from './actions';
import { Actions } from './components/ToolBarActions';
import { Nav } from './components/ToolBarNav';
import { Feedback } from '../../app/components/Feedback';
import { asEntryDate } from './utils';
import { selectUserRole } from '../authentication/authenticationSlice';
import { SideBar } from './components/SideBar';
import {LayoutNodes} from './components/LayoutNodes';

function getCurrentOrPreviousDay(): Date {
  const currentDate = new Date();
  // Check if the current hour is less than 1
  if (currentDate.getHours() < 1) {
    // Subtract one day (24 hours) in milliseconds
    currentDate.setDate(currentDate.getDate() - 1);
  }

  return currentDate;
}

export function EditEntryWithTemplate() {
  const dispatch = useAppDispatch();
  const { shopId } = useParams();
  const entry = useAppSelector(selectEntry);

  // Use the current date or the previous day if the current time is before 01h00
  const [entryDate, setEntryDate] = useState<string>(asEntryDate(getCurrentOrPreviousDay()));
  const [shop, setShop] = useState<EntryShop>();
  const [initialized, setInitialized] = useState<boolean>(true);
  const [localeFieldValues, { set: setLocalFieldValueNumber, reset: resetLocalFieldValueNumber }] = useMap<
    string,
    number
  >([]);
  const [localeFieldValuesText, { set: setLocalFieldValueText, reset: resetLocalFieldValueText }] = useMap<
    string,
    string
  >([]);
  const [viewMode, setViewMode] = useState<ViewMode>('read');

  const shops = useAppSelector(selectShops);
  const isWaiting = useAppSelector(selectIsWaiting);
  const errorMessage = useAppSelector(selectErrorMessage);
  const userRole = useAppSelector(selectUserRole);
  const layoutTemplate = useAppSelector(selectLayoutTemplate);

  // Fetch data that does not change with entry date
  useEffect(() => {
    if (typeof shopId !== 'string') {
      return;
    }
    const shop: ObjectWithId = { id: shopId };
    // Get the list of shops
    dispatch(findShops());
    // Get the list of balances
    dispatch(findAllBalances());
    // Get Shop layout-template
    dispatch(findLayoutTemplate({ shop }));
  }, [dispatch, shopId]);

  // Fetch Entry and Shop (will also run on every datepicker date change)
  useEffect(() => {
    if (typeof shopId !== 'string') {
      return;
    }
    const shop: ObjectWithId = { id: shopId };
    dispatch(findShopOnDay({ shop, date: entryDate }));
    // Get status for 'status' and 'balance'
    dispatch(findStatus({ shop, date: entryDate }));
  }, [dispatch, shopId, entryDate]);

  // Set local Entry based on store values
  useEffect(() => {
    if (!entry || (entry.entryDate !== entryDate && initialized === true)) {
      return;
    }
    resetLocalFieldValueNumber();
    resetLocalFieldValueText();
    (entry.entryValues || []).forEach((entryValue: EntryValue) => {
      if (get(entryValue.fieldFragment, 'inputType') === 'number') {
        return setLocalFieldValueNumber(entryValue.fieldFragment.id, +entryValue.value);
      }
      if (get(entryValue.fieldFragment, 'inputType') === 'text') {
        return setLocalFieldValueText(entryValue.fieldFragment.id, entryValue.textValue);
      }
    });
    setViewMode('read');
    setInitialized(true);
  }, [
    entry,
    setLocalFieldValueNumber,
    setLocalFieldValueText,
    resetLocalFieldValueNumber,
    entryDate,
    resetLocalFieldValueText,
    initialized,
  ]);

  // Set Shop, shop has all FieldDefinitions and FieldFragments for the Entry shop
  useEffect(() => {
    if (!(shops && entry && entry.shop)) {
      return;
    }
    if (shop && shop.id === shopId && shop.id === entry.shop.id) {
      return;
    }
    setShop(shops.find((curr) => curr.id === entry.shop.id));
  }, [entry, shops, shop, shopId]);

  // For updates only create EntryValues
  // For new entries first create Entry and then create EntryValues
  const onSubmit = useCallback(async () => {
    if (!entry) {
      return;
    }
    const newEntry = cloneDeep(entry);
    newEntry.entryValues = [
      ...Array.from(localeFieldValues.keys())
        .filter((key) => typeof localeFieldValues.get(key) !== 'undefined')
        .map((key) => ({
          fieldFragment: { id: key },
          value: localeFieldValues.get(key) || 0,
          textValue: '',
        })),
      ...Array.from(localeFieldValuesText.keys())
        .filter((key) => typeof localeFieldValuesText.get(key) !== 'undefined')
        .map((key) => ({
          fieldFragment: { id: key },
          value: 0,
          textValue: localeFieldValuesText.get(key) || '',
        })),
    ];
    // Create and wait from new Entry
    if (!newEntry.id) {
      const result = await dispatch(create({ entry: newEntry }));
      newEntry.id = result?.payload?.id;
      // Get status for 'status' and 'balance'
      dispatch(findStatus({ shop: { id: shopId }, date: entryDate }));
    }
    // Create new EntryValues
    if (!newEntry.id) {
      console.log('No Entry created');
      return;
    }
    await dispatch(
      createValues({
        entryValues: newEntry.entryValues,
        entry: { id: newEntry.id },
      })
    );
    // Get status for 'status' and 'balance'
    dispatch(findStatus({ shop: { id: shopId }, date: entryDate }));

    return false;
  }, [dispatch, localeFieldValues, localeFieldValuesText, entry, entryDate, shopId]);

  const onChangeDate = useCallback((date: Date) => {
    // @TODO prevent future date using manual entry from datepicker
    if (!date && !isISO8601(date)) {
      console.log(`[Entry] invalid entry date ${date}`);
      return;
    }
    setInitialized(false);
    setEntryDate(asEntryDate(date));
  }, []);

  const updateEntry = (fieldFragment: FieldFragment, value: number | string) => {
    if (fieldFragment.inputType === 'number' && typeof value === 'number') {
      setLocalFieldValueNumber(fieldFragment.id, value);
    } else if (fieldFragment.inputType === 'text' && typeof value === 'string') {
      setLocalFieldValueText(fieldFragment.id, value);
    }
  };

  return (
    <>
      <ToolBar
        title={`${(shop && shop.name) || ''}`}
        nav={<Nav shops={shops} shop={shop} />}
        actions={
          <Actions
            onSave={onSubmit}
            disabled={isWaiting}
            entryDate={entryDate}
            onChangeDate={onChangeDate}
            onViewChange={setViewMode}
            view={viewMode}
          />
        }
      />
      {entry && (
        <div>
          <div className="grid grid-cols-12 gap-8">
            <div className="grid grid-cols-1 gap-8 col-span-8">
              {shop && layoutTemplate && (
                <LayoutNodes
                  layoutNodes={layoutTemplate}
                  shop={shop}
                  onChange={updateEntry}
                  values={localeFieldValues}
                  textValues={localeFieldValuesText}
                  canEdit={viewMode !== 'read'}
                  userRole={userRole}
                />
              )}
            </div>
            <div className="col-span-3">
              <SideBar
                entry={entry}
                shop={shop}
                entryDate={entryDate}
              />
            </div>
          </div>
        </div>
      )}
      <Feedback errorMessage={errorMessage} />
    </>
  );
}
