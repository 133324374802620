import { DeleteOutlined } from '@ant-design/icons';
import { type FC } from 'react';
import { useDrop } from 'react-dnd';

const ACCEPTS = ['ROW', 'COLUMN', 'COMPONENT', 'FIELD_FRAGMENT'];

interface ComponentProps {
  layout: Array<LayoutNode>;
  onDrop: Function;
}

export const TrashDropZone: FC<ComponentProps> = ({ onDrop, layout }) => {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: ACCEPTS,
      drop: (item, _monitor) => {
        onDrop(layout, item);
      },
      canDrop: (item: any, _monitor) => {
        const itemPath = item.path;
        const splitItemPath = itemPath.split('-');
        const itemPathRowIndex = splitItemPath[0];
        const itemRowChildrenLength = (layout[itemPathRowIndex] && layout[itemPathRowIndex].children.length) || 0;

        // prevent removing a col when row has only one col
        if (item.type === 'COLUMN' && itemRowChildrenLength && itemRowChildrenLength < 2) {
          return false;
        }

        return true;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [layout, onDrop],
  );

  const isActive = isOver && canDrop;
  return (
    <div className={`trashDropZone trashDropZoneFixed ${isActive ? 'active' : ''}`} ref={drop}>
      <DeleteOutlined />
    </div>
  );
};
