import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { handleServiceError } from '../../api/utils';
import { layoutTemplatesService } from './layoutTemplatesService';
import { successToast } from '../toast/toastSlice';

export const findAllLayoutTemplates = createAsyncThunk<any, undefined, { rejectValue: string; dispatch: any }>(
  'layoutTemplates/findAll',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return await layoutTemplatesService().findAll();
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const findOneLayoutTemplate = createAsyncThunk<any, string, { rejectValue: string; dispatch: any }>(
  'layoutTemplates/findOne',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      return await layoutTemplatesService().findOne({ id });
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const updateLayoutTemplate = createAsyncThunk<
  any,
  { layoutTemplate: Partial<LayoutTemplate>; cb?: Function },
  { rejectValue: string; dispatch: any }
>('layoutTemplates/update', async ({ layoutTemplate, cb }, { rejectWithValue, dispatch }) => {
  try {
    if (!layoutTemplate?.id) {
      throw new Error('layoutTemplate has no id');
    }
    const result: any = await layoutTemplatesService().update(layoutTemplate);
    if (result && result.id) {
      dispatch(successToast('api.layoutTemplate.updated'));
      if (cb) {
        cb(result);
      }
      return result;
    }
  } catch (err: any) {
    console.log(err);
    handleServiceError(err, dispatch);
    return rejectWithValue(err);
  }
});

export const createLayoutTemplate = createAsyncThunk<
  any,
  Partial<LayoutTemplate>,
  { rejectValue: string; dispatch: any }
>('layoutTemplates/create', async (layoutTemplate, { rejectWithValue, dispatch }) => {
  try {
    const result = await layoutTemplatesService().create(layoutTemplate);
    return result;
  } catch (err: any) {
    console.error(err);
    handleServiceError(err, dispatch);
    return rejectWithValue(err);
  }
});

export const findOneWithShops = createAsyncThunk<any, string, { rejectValue: string; dispatch: any }>(
  'layoutTemplates/findOneWithShops',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      return await layoutTemplatesService().findOneWithShops({ id });
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const resetLayoutTemplate = createAction('layoutTemplates/reset');
