import { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { ToolBar } from '../../app/components/ToolBar';
import { ToolBarSearch } from '../../app/components/ToolBarSearch';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LinkButton } from '../../app/components/LinkButton';
import { verbose } from './constants';
import { findAllLayoutTemplates, resetLayoutTemplate } from './layoutTemplateActions';
import { selectLayoutTemplates } from './layoutTemplatesSlice';
import { sortBy } from 'lodash';
import { Button } from 'antd';
import { InfoDrawer } from './components/InfoDrawer';
import { useBoolean } from 'ahooks';

interface ActionsProps {
  onSearch: Function;
}
const Actions = (props: ActionsProps) => (
  <>
    <ToolBarSearch placeholder="layout-templates.search" onChange={props.onSearch} />
    <LinkButton to="/layout-templates/add" label="layout-templates.add" />
  </>
);

function sortShopsByName(layoutTemplate: LayoutTemplate) {
  return layoutTemplate.name.toLowerCase();
}

export function LayoutTemplatesOverview() {
  const dispatch = useAppDispatch();
  const layoutTemplates = useAppSelector(selectLayoutTemplates);
  const [localLayoutTemplates, setLocalLayoutTemplates] = useState<LayoutTemplate[]>([]);
  const [localLayoutTemplate, setLocalLayoutTemplate] = useState<LayoutTemplate>();
  const [showInfoDrawer, { toggle: toggleInfoDrawer, setFalse: hideInfoDrawer }] = useBoolean(false);

  useEffect(() => {
    dispatch(findAllLayoutTemplates());
    dispatch(resetLayoutTemplate()); // Reduce extra update in "layout-templates-edit" route
  }, [dispatch]);

  useEffect(() => {
    if (!layoutTemplates || !Array.isArray(layoutTemplates)) {
      return;
    }
    setLocalLayoutTemplates(sortBy(layoutTemplates, [sortShopsByName]));
  }, [layoutTemplates]);

  // Filter local layoutTemplates
  const filterShopLayoutTemplates = useCallback(
    (value: string) => {
      if (Array.isArray(layoutTemplates)) {
        if (value.length === 0) {
          setLocalLayoutTemplates(sortBy(layoutTemplates, 'name'));
        } else {
          setLocalLayoutTemplates(
            sortBy(
              layoutTemplates.filter((layoutTemplate) => (layoutTemplate.name || '').toLowerCase().includes(value)),
              [sortShopsByName],
            ),
          );
        }
      }
    },
    [layoutTemplates],
  );

  const showInfoDrawerFor = (layoutTemplate: LayoutTemplate) => {
    setLocalLayoutTemplate(layoutTemplate);
    toggleInfoDrawer();
  };

  return (
    <>
      <ToolBar title="layout-templates.title" actions={<Actions onSearch={filterShopLayoutTemplates} />} />
      <div className="flex gap-x-4">
        {/** DEFAULT LAYOUT TEMPLATE */}
        {verbose > 0 && <LinkButton to={`/layout-templates/poc`} label="POC" />}
      </div>

      {/**
        LAYOUT TEMPLATES
        */}

      <div className="o-table">
        <table className="table-auto">
          <thead className="capitalize">
            <tr>
              <td>
                <Trans i18nKey="shop.layout-template" />
              </td>
              <td>
                <Trans i18nKey="assigned shops" />
              </td>
            </tr>
          </thead>
          <tbody>
            {localLayoutTemplates.map((c) => (
              <tr className="o-table-row" key={c.id}>
                <td>
                  <Link
                    to={`/layout-templates/edit/${c.id}`}
                    className={`${c.name === '' ? 'italic font-semibold' : ''}`}
                  >
                    {c.name || 'untitled'}
                  </Link>
                </td>
                <td>
                  <Button
                    type="link"
                    onClick={() => {
                      showInfoDrawerFor(c);
                    }}
                  >
                    {' '}
                    {c?.shopLayoutTemplates?.length || '0'}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showInfoDrawer && <InfoDrawer onClose={hideInfoDrawer} layoutTemplate={localLayoutTemplate} />}
    </>
  );
}
