import { Trans } from 'react-i18next';
import { Balance } from './Balance';
import { Status } from './Status';
import { Profile } from './Profile';
import { History } from './History';
import { useBoolean } from 'ahooks';

export function SideBar({ entry, shop, entryDate }: { entry: Entry; shop: EntryShop | undefined; entryDate: string }) {
  const [showHistory, { toggle: toggleHistory, setFalse: hideHistory }] = useBoolean(false);

  return (
    <div className="col-span-3">
      <div className="divide-y divide-gray-light bg-gray-default text-white">
        {/** PROFILE  */}
        <Profile entry={entry} shop={shop} />
        {/** STATUS  */}
        <div className="p-6">
          <Status entry={entry} entryDate={entryDate} fieldDefinitions={shop?.fieldDefinitions} />
        </div>
        {/** HISTORY  */}
        <button className="p-4 w-full" onClick={toggleHistory}>
          <Trans i18nKey="entries.show-history" />
        </button>
        {showHistory && <History entry={entry} onClose={hideHistory} />}
      </div>
      <div className="bg-white p-6 mt-8">
        {/** DAILY-TOTALS  */}
        <h2>
          <Trans i18nKey="balance" />
        </h2>
        <Balance />
      </div>
    </div>
  );
}
