import { EditOutlined } from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';
import React, { CSSProperties, useState } from 'react';

interface EditableHeaderProps {
  initialText: string | undefined;
  onChange: (label: string) => void;
  headerStyle?: CSSProperties;
  modalTitle?: string;
}

export const EditableHeader: React.FC<EditableHeaderProps> = ({
  initialText = '',
  modalTitle = 'Edit',
  onChange,
  headerStyle = {},
}) => {
  const [headerText, setHeaderText] = useState(initialText);
  const [localHeaderText, setLocalHeaderText] = useState(initialText);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setHeaderText(localHeaderText);
    if (onChange) {
      onChange(localHeaderText);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLocalHeaderText(headerText);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalHeaderText(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevents form submission behavior
    handleOk();
  };

  return (
    <div className="flex gap-x-2 content-center m-2">
      <>
        <h2 style={headerStyle}>{headerText}</h2>
        <Button type="text" icon={<EditOutlined />} onClick={showModal} shape="circle" />
      </>
      <Modal title={modalTitle} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <form onSubmit={handleSubmit}>
          <Input value={localHeaderText} onChange={handleInputChange} allowClear />
        </form>
      </Modal>
    </div>
  );
};
